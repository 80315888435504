import { Button, Card, Dialog } from '@dayinsure/components';
import { Row } from './Row';
import { findAdministrationFee, isMonthlyPaymentPlan } from '../../../../helpers';
import { MotorPolicyTransactionDto } from '../../../../api/v1';

type PaymentDetailModalProps = {
  isOpen: boolean;
  testId: string;
  transaction: MotorPolicyTransactionDto;
  onClose: () => void;
};

export const PaymentDetailModal = ({
  isOpen,
  testId,
  transaction,
  onClose,
}: PaymentDetailModalProps) => {
  const isMonthly = isMonthlyPaymentPlan(transaction.paymentPlan?.type?.code);
  const administrationFee = findAdministrationFee(transaction.price?.fees);
  const installmentDetails = transaction.paymentPlan?.installmentDetails;

  return (
    <Dialog open={isOpen} onClose={onClose} title="Payment details">
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
      >
        <div className="flex flex-col w-full">
          <Row
            label="Insurance premium"
            value={`£${transaction.price?.netPremium?.amount?.toFixed(2)}`}
          />
          {transaction.price?.addOns?.map(addOn => (
            <Row
              key={addOn.type?.code}
              label={addOn.type?.description || ''}
              value={`£${(addOn.price?.total?.amount || 0).toFixed(2)}`}
            />
          ))}
          {administrationFee?.total?.amount && (
            <Row
              label="Set-up fee (non-refundable)"
              value={`£${administrationFee.total.amount.toFixed(2)}`}
            />
          )}
          <Row
            label="Insurance Premium Tax"
            value={`£${transaction.price?.insurancePremiumTax?.amount?.toFixed(2)}`}
          />
          {isMonthly && (
            <>
              <Row
                label="Amount to be financed"
                value={`£${installmentDetails?.financeAmount?.amount?.toFixed(2)}`}
              />
              <Row label="Interest rate" value={`${installmentDetails?.interestRate}%`} />
              <Row
                label="Representative APR"
                value={`${installmentDetails?.aprFromProvider?.toFixed(2)}%`}
              />
              <Row
                label={`${installmentDetails?.numberOfInstallments} monthly payments`}
                value={`£${installmentDetails?.monthlyCost?.amount?.toFixed(2)}`}
              />
            </>
          )}
          <Row
            label={isMonthly ? 'Total amount payable' : 'Total paid'}
            value={`£${transaction?.paymentPlan?.installmentDetails?.total?.amount?.toFixed(
              2
            )}`}
          />
        </div>
      </Card>

      <Button
        id={`${testId}_payment-detail_close-button`}
        testId={`${testId}_payment-detail_close-button`}
        fullWidth
        onClick={onClose}
        text="Close"
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};
