export const fixNegativePriceDisplay = (price?: number): string | undefined => {
  if (!price && price !== 0) {
    return undefined;
  }

  if (price < 0) {
    return `-£${Math.abs(price).toFixed(2)}`;
  }

  return `£${price.toFixed(2)}`;
};
